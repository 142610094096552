import React, { useState, useEffect } from 'react'
import * as S from './style'

import OpenAccountPFForm from 'src/components/UI/Forms/OpenAccounPFForm'
import Layout from 'src/layouts/BaseLayout'
import pageContext from './pageContext.json'

import { getParameterByName } from 'src/shared/helpers'

import { alphaCode } from 'src/utils/alpha-code-utms'

const AbrirContaPF = () => {
  const [ utmSource, setUtmSource ] = useState<string>('9io9bkj')
  const [ utmCampaign, setUtmCampaign ] = useState<string>('site_contadigital_aquisicao')

  useEffect(() => {
    setUtmSource(getParameterByName('utm_source', '') as string || '9io9bkj')
    setUtmCampaign(getParameterByName('utm_campaign', '') as string || 'site_contadigital_aquisicao')
  }, [])

  return (
    <Layout pageContext={pageContext}>
      <S.Image />
      <S.Wrapper>
        <div className='row mx-0 justify-content-center'>
          <div className='col-12 col-lg-6' />
          <div className='col-12 col-lg-6 col-xl-5 px-0'>
            <OpenAccountPFForm
              customOrigin='LP Conta Digital PF avulsa'
              mobileLink={`https://bancointer.go.link?adj_t=${alphaCode(utmSource)}&adj_campaign=${utmCampaign}&adj_adgroup=contadigital&adj_creative=lp-abertura-conta&adj_label=aquisicao&adj_fallback=https%3A%2F%2Finter.co%2F&adj_redirect_macos=https%3A%2F%2Finter.co%2F&adj_redirect_ios=https%3A%2F%2Fgointer.app%2F`}
            />
          </div>
        </div>
      </S.Wrapper>
    </Layout>
  )
}

export default AbrirContaPF
