import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'

import ImageTablet from './assets/bg-cd-pf-inter-tablet.png'
import ImageLG from './assets/bg-cd-pf-inter-desktop-lg.png'
import ImageXL from './assets/bg-cd-pf-inter-desktop-xl.png'
import ImageXXXL from './assets/bg-cd-pf-inter-desktop-xxxl.png'

export const Image = styled.div`
  width: 100%;
  overflow: hidden;
  
  @media ${device.tablet} {
    height: 480px;
    background-image: url(${ImageTablet});
    background-repeat: no-repeat;
    background-size: cover;
  }

  @media ${device.desktopLG} {
    background-image: url(${ImageLG});
    width: 50%;
    height: 100vh;
    position: absolute;
  }

  @media ${device.desktopXL} {
    background-image: url(${ImageXL});
    border-top-right-radius: 160px;
  }

  @media ${device.desktopXXXL} {
    background-image: url(${ImageXXXL});
    background-position: top right;
  }
`
export const Wrapper = styled.section`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: start;
  overflow: hidden;
  padding-top: 48px;
  padding-bottom: 48px;

  @media ${device.tablet} {
    min-height: auto;
  }
  
  @media ${device.desktopLG} {
    height: 100vh;
    overflow: auto;
  }

  @media ${device.desktopXXXL} {
    justify-content: center;
  }

  input::placeholder {
    font-weight: 700;
    color: ${grayscale[300]};
  }
`
